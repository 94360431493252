@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.profile-reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  border-radius: 6px;
  font-family: Roboto;

  &__highlight {
    @extend %highlight;
  }

  &__item {
    position: relative;
    padding: 12px 10px 10px;
    background: $color-white;
    margin: 10px;
    border: 1px solid $color-gray;
    border-radius: 8px;
  }
  &__count {
    margin-left: 15px;
    color: $color-white;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
  }

  &__input {
    width: 328px;
    padding: 11px 10px 8px;
    border: none;
    border-radius: 6px;
    outline: none;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
  }

  &__rating {
    color: $color-white;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    &-icon {
      margin-right: 13px;
      color: $color-white;
    }
  }

  &__scrollbar {
    &-wrap {
      width: 1190px;
      height: 600px;
    }

    &-thumb {
      border-radius: 6px;
      background: $color-white;
    }

    &-track {
      position: absolute;
      top: 2px;
      right: 0;
      bottom: 2px;
      width: 4px;
      border-radius: 3px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    width: 1396px;
    margin-bottom: 18px;
    padding: 0 10px 5px;
    font-size: 14px;

    .search-input__btn {
      top: 18px;
      right: 5px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
  &__search {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 58px;

    &-wrapper {
      width: 328px;
    }
  }
  &__search-input {
    padding: 7px;
    border-radius: 6px;
    &:focus {
      outline: none;
    }
  }
  &__btn {
    // position: absolute;
    // right: 5px;
    // top: 9px;
    // cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
    }

    &--save {
      color: $color-eucalyptus;
    }

    &--edit {             
      color: $color-azure-radiance;
    }

    &--delete {
      margin-left: 7px;
      color: $color-froly;
    }

    &--search {
      color: $color-silver;
    }
    &--cancel {
      color: $color-red;
    }
  }

  &__sort {
    .react-select {
      width: 170px;
      $block: &;

      .react-select__control {
        cursor: pointer;
        background-color: $color-white;
        padding-left: 10px;
        border-radius: 6px;

        &--menu-is-open {
          border-bottom: none;
        }
      }

      // .react-select__menu {
      //   border: solid 1px $color-gray;
      //   border-top: none;
      // }
    }
  }

  &__close {
    margin-right: 68px;
    color: $color-white;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    cursor: pointer;

    svg {
      width: 11px;
      height: 11px;
      margin-right: 9px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-mecury;
    font-size: 11px;
    padding: 0 0 10px;
  }

  &__src-wrap {
    margin-left: 15px;
    color: $color-gray-approx;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  &__name {
    width: 75px;
    margin-left: 5px;
    color: $color-dodger-blue;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    text-align: left;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__info {
    color: $color-gray-approx;
    display: flex;
    max-width: 96px;
    flex-wrap: wrap;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    &--show {
      display: flex;
      align-items: center;
      color: $color-azure-radiance;
      letter-spacing: 0.5px;
    }

    &-status {
      margin-right: 13px;
    }

    &--edit {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 56px;
      gap: 4px;

      & > div {
        display: flex;
        align-items: center;
  
        & > div {
          display: flex;
          gap: 7px;
        }
      }
    }

    .toggle {
      width: 24px;
      height: 10px;

      &__wrap {
        margin: 0 0 0 5px;
      }
      &__indicator {
        height: 8px;
        border-radius: 4px;
        background-color: $color-white;
        
        &--true {
          transform: translate(40%, -50%);
        }
      }

      &--true {
        background-color: $color-jade;
      }
      &--false {
        background-color: $color-silver;
      }
    }

    // &--edit {
    //   margin-right: 25px;

    //   .edit-icon {
    //     &__save,
    //     &__cancel {
    //       font-size: 17px;
    //     }
    //     &__save {
    //       margin-right: 15px;
    //     }
    //   }
    // }

    // svg {
    //   font-size: 15px;
    //   cursor: pointer;
    // }
  }
  // &__stars{
  //   display: flex;
  //   color: $color-grey;
  //   font-size: 12px;
  //   span {
  //     margin-top: 1px;
  //   }
  // }
  // &__star{
  //   margin-right: 5px;
  //   &:last-of-type{
  //     margin-right: 10px;
  //   }
  // }
  &__review-rating {
    margin-left: 182px;
    color: $color-black;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 16px;

    &-value {
      margin-right: 13px;
      color: $color-black;
    }
  }
  &__text {
    display: flex;
    padding: 10px 0 0;
    font-size: 12px;
    line-height: 16px;
    text-align: left;

    &--value {
      width: 100%;
      border: 1px solid transparent;
      overflow: hidden;
      color: $color-gray-approx;
      font-size: 14px;
      line-height: 19px;
      font-family: Roboto;
      letter-spacing: 0;
      resize: none;
      background: transparent;
    }
    &--active {
      border: 1px solid $color-gray;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
  }

  &__pending {
    display: flex;
    z-index: 10;
    position: fixed;
    top: 65px;
    right: 0;
    bottom: -10px;
    left: 0;
    justify-content: center;
    align-items: center;

    &--item {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0);
      animation: fadeIn 0.5s cubic-bezier(0.165, 0.14, 0.44, 1) forwards;
    }
  }
  &__error {
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 11px;
    color: $color-punch;
    letter-spacing: 0.3px;
  }
  
  &__toggle-wrap {
    position: sticky;
    color: $color-gray;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    width: 150px;

    & .toggle-switch__slider {
      background-color: green;
    }
  }

  &__checkbox-label {
    flex-wrap: wrap;
    color: $color-gray;
    display: flex;
    gap: 3px;
    color: $color-white;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
  }

  &__checkbox-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &-tab {
    height: 100%;

    &__header {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      padding: 0 10px 10px;
    }

    &__checkbox-wrap {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 4px;
    }

    &__checkbox-label {
      flex-wrap: wrap;
      color: $color-gray;
      font-size: 13px;
      display: flex;
      gap: 3px;
      cursor: pointer;
      align-items: center;
    }

    &__title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-mecury;
      font-size: 11px;
      padding: 0 0 10px;
    }

    &__name {
      width: 75px;
      margin-left: 5px;
      color: $color-dodger-blue;
      font-size: 12px;
      line-height: 16px;
      font-family: Roboto;
      text-align: left;
      letter-spacing: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__search {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    }

    &__count {
      width: 40px;
    }

    &__scrollbar-wrap {
      height: calc(100% - 85px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__info {
      color: $color-gray-approx;
      display: flex;
      max-width: 96px;
      flex-wrap: wrap;
      &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__rating {
      flex-wrap: wrap;
      color: $color-gray;
      font-size: 13px;
      display: flex;
      gap: 3px;
      align-items: center;
    }

    &__item {
      position: relative;
      padding: 12px 10px 10px;
      background: $color-white;
      margin: 10px;
      border-radius: 8px;
    }

    &__search-wrapper {
      & .search-input__btn {
        width: 14px;
        height: 14px;
      }
    }

    &__input {
      padding: 5px 27px 5px 10px;
      border: none;
      border-radius: 6px;
      outline: none;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.88px;
    }

    &__scrollbar {  
      &-thumb {
        border-radius: 6px;
        background-color: $color-silver;
      }
  
      &-track {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 2px;
        width: 4px;
        border-radius: 3px;
      }
    }

    &__review-rating {
      color: $color-black;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 16px;
  
      &-value {
        margin-right: 13px;
        color: $color-gray-approx;
      }
    }

    &__info {
      color: $color-gray-approx;
      display: flex;
      max-width: 96px;
      flex-wrap: wrap;
      &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
  
      &--show {
        display: flex;
        align-items: center;
        color: $color-azure-radiance;
        letter-spacing: 0.5px;
      }
  
      &-status {
        margin-right: 13px;
      }
  
      &--edit {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 56px;
        gap: 4px;
  
        & > div {
          display: flex;
          align-items: center;
    
          & > div {
            display: flex;
            gap: 7px;
          }
        }
      }
  
      .toggle {
        width: 24px;
        height: 10px;
  
        &__wrap {
          margin: 0 0 0 5px;
        }
        &__indicator {
          height: 8px;
          border-radius: 4px;
          background-color: $color-white;
          
          &--true {
            transform: translate(40%, -50%);
          }
        }
  
        &--true {
          background-color: $color-jade;
        }
        &--false {
          background-color: $color-silver;
        }
      }
    }
  
    &__btn {
      svg {
        width: 14px;
        height: 14px;
      }
  
      &--save {
        color: $color-eucalyptus;
      }
  
      &--edit {             
        color: $color-azure-radiance;
      }
  
      &--delete {
        margin-left: 7px;
        color: $color-froly;
      }
  
      &--search {
        color: $color-silver;
      }
      &--cancel {
        color: $color-red;
      }
    }

    &__src-wrap {
      margin-left: 15px;
      color: $color-gray-approx;
      display: flex;
      gap: 3px;
      flex-wrap: wrap;
    }
  }
}
