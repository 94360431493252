@import '../../assets/scss/variables.scss';

.adr-book-search-filters {
  display: flex;
  align-items: center;

  &__btn {
    transition: .3s;
    &--selected {
      color: $color-bondi-blue;
    }

    &--no-open {
      cursor: not-allowed;
    }
  }

  .react-select {
    &__control {
      width: 47px !important;
      padding: 0 5px !important;
    }

    &__value-container {
      display: flex;
    }

    &__menu-list {
      max-height: 100%;
    }
  }
}