@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.booking-edit-form {
  width: 1140px;
  max-height: 100vh;
  padding: 30px 0;

  --info-block-padding: 5px;

  &__client-tag {
    background-color: $color-dark-background-tag;
  }

  &__main {
    display: flex;
    position: relative;
    height: 800px;
    margin-top: 36px;
    border-radius: 6px;
    color: $color-black;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 1px;
    background-color: $color-white;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
  }

  &__requirements-title {
    display: flex;
    align-items: center;
    margin: 16px 0 9px 6px;
    color: $color-azure-radiance;
    &--has-agent {
      color: $color-seance;
    }
  }

  &__spinner-updating {
    margin-left: 20px;
  }

  &__content-wrapper {
    position: relative;
    flex-shrink: 0;
    max-width: 734px;
    padding: 20px 41px 32px 60px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: $color-mystic;
    }
  }

  &__operator {
    display: flex;
    align-items: center;
  }

  &__operator-title {
    margin-right: 6px;
    color: $color-bali-hai;
    font-size: 12px;
    line-height: 27px;
    letter-spacing: 0;
  }

  &__booking-total-rate {
    font-weight: bold;
  }

  &__selected-profiles {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin-left: 30px;
    width: 331px;

    &-spinner-wrapper {
      display: flex;
      opacity: 0.6;
      z-index: 1;
      position: absolute;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: $color-white;
    }

    &--is-no-profiles {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 329px;
      height: 94px;
    }
  }

  &__duo-logo {
    position: absolute;
    top: 26px;
    right: 40px;
    color: $color-razzle-dazzle-rose;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
  }

  &__diva-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }

  &__diva-control-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40px;

    & .feedback-contact-field {
      width: auto;

      &__button {
        border: none;
      }
    }
  }

  &__diva-info-block {
    display: flex;
    flex-grow: 1;
    border-radius: 6px;
    overflow: hidden;
    background-color: $color-mystic;

    .lazy-load__container {
      width: 64px;
      height: 94px;
    }

    .lazy-load__image {
      border-radius: 6px;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__diva-info {
    position: relative;
    flex-grow: 1;
    width: 250px;
    height: 94px;
    margin-left: 15px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__diva-name {
    display: flex;
    align-items: center;
    color: $color-azure-radiance;
    margin: 6px 0 6px 6px;

    &::before {
      display: block;
      width: 7px;
      height: 7px;
      margin-right: 10px;
      border-radius: 50%;
    }

    &--available-now::before {
      content: '';
      background-color: $color-caribian-green;
    }

    &--off-today::before {
      content: '';
      background-color: $color-radical-red;
    }
  }

  &__no-tags {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    color: $color-gray;
    font-size: 10px;
  }

  &__client {
    &-info {
      display: flex;

      .lazy-load__container {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &-info-block {
      position: relative;
      height: 96px;
      padding: var(--info-block-padding);
      border: 1px solid $color-mystic;
      border-radius: 6px;
      width: 270px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &-text-content {
      display: flex;
      flex-direction: column;
      max-width: 210px;
      margin-left: 10px;
    }

    &-name {
      overflow: hidden;
      cursor: pointer;
      color: $color-dodger-blue;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }

    &-contact {
      margin-top: 6px;
      overflow: hidden;
      color: $color-gray;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }

    &-tags-wrapper {
      margin-top: 4px;
      margin-left: 10px;

      & .contact-tags__tag--hidden-count {
        height: 16px;
        line-height: 13px;
        width: 36px;
      }
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    border-radius: 6px;
    color: $color-tundora-light;
    height: 16px;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0;
    background-color: $color-athens-gray;
    margin-right: 0;

    & > span {
      line-height: 13px;
    }
  }

  &__header {
    display: flex;
    position: relative;
    justify-content: space-between;
  }

  &__btns-wrap {
    display: flex;
    align-items: center;

    .feedback-contact-field {
      width: 138px;
      margin-left: 60px;

      &__button {
        width: 138px;
        border: none;
      }
    }
  }

  &__status-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: 98px;
    background: $color-white;
    align-self: flex-start;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    margin-left: 32px;
    font-size: 12px;
    border-radius: 6px;
  }

  &__status-popup {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 240px;
    padding: 12px 15px;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    z-index: 1;

    &-header {
      color: $color-gray;
      margin-bottom: 15px;
      text-align: start;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        display: flex;
        align-items: center;
        padding: 2px 10px;
        border-style: solid;
        border-width: 1px;
        border-radius: 6px;
        margin-right: 15px;
        margin-bottom: 11px;
        cursor: pointer;

        .booking-form__dot {
          margin-left: 5px;
        }
      }
    }
  }

  &__dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__profile {
    &-tags-wrapper {
      margin-top: 10px;
    }
  }

  &__note {
    margin-top: 10px;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-gray;
    outline: none;

    &-caption {
      display: flex;
    }

    &-icon {
      width: 15px;
      height: 15px;
      margin-right: 8px;
      color: $color-persimmon;
    }

    &-attach-icon {
      width: 15px;
      height: 15px;
      color: $color-bondi-blue;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 2px;
      color: $color-black;
      font-size: 12px;
      line-height: 16px;
      font-family: Roboto;
      letter-spacing: 1px;
    }

    &-textarea {
      max-width: 326px;
      height: 96px;
      overflow-y: scroll;
      color: $color-black;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0;
      word-wrap: break-word;
      resize: none;
      @include scrollBars;

      &-wrap {
        border: 1px solid $color-silver;
        height: 111px;
        margin-top: 4px;
        padding: 10px;
      }
    }
  }

  &__attachment-list {
    margin: 12px;
  }

  &__attachment-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    &:hover * {
      visibility: visible;
    }
  }

  &__atachment-link-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    padding: 0;
    color: $color-persimmon;
    visibility: hidden;
  }

  &__attachment-link {
    color: blue;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  &__fields-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 23px 18px 19px;
    border-bottom: 1px solid $color-gray;
    text-align: left;

    &-title {
      margin-bottom: 17px;
    }

    &--time {
      padding: 25px 18px 19px;
    }

    &--select-fields {
      padding: 27px 0 30px;
    }

    &--services {
      padding: 26px 17px 28px;
    }

    &--contacts {
      flex-wrap: nowrap;
      align-items: flex-start;
      margin-top: 15px;
      padding: 10px 0 8px;
      border-top: 1px solid $color-gray;
    }

    &--booking-details {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0 15px 0;
    }

    &--special-request {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding: 5px 0 15px 0;
    }

    &--booking-address {
      position: relative;
      padding: 5px 0 15px 0;
      justify-content: space-between;
    }

    &--messages {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 629px;
      padding: 5px 0 15px 0;
    }

    &--prebookings {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 629px;
      padding: 5px 0 15px 0;
    }
  }

  &__field {
    &--type {
      display: flex;
      position: relative;
      flex-direction: column;
    }

    &--duration {
      margin-left: 15px;
    }
  }

  &__booking-type {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;

    & input {
      display: none;
    }
  }

  &__btn {
    padding: 5px 24px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 2px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &-icon {
      color: $color-bondi-blue;
      width: 16px;
      height: 16px;

      &--add-special {
        width: 26px;
      }

      &--edit {
        margin: 0;
      }

      &--delete-profile {
        width: 6px;
        height: 6px;
        margin: 0;
        color: $color-white;
      }

      &--operator {
        width: 14px;
        margin: 0;
      }

      &--edit-details,
      &--edit-special-request {
        color: $color-gray;
      }

      &--cancel {
        width: 13px;
        margin-right: 9px;
        color: $color-white;
      }
    }

    &--add-escort {
      display: flex;
      align-items: center;
      padding: 18px 21px;
      color: $color-black;
      text-transform: capitalize;
      letter-spacing: 1px;
      background: $color-bermuda;
      gap: 20px;
    }

    &--edit-details,
    &--edit-special-request {
      position: absolute;
      top: 18px;
      left: -40px;
      padding: 0;
    }

    &--operator {
      display: flex;
      align-items: center;
      margin-left: 22px;
      padding: 0;
      color: $color-bondi-blue;
    }

    &--add-duo {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 138px;
      height: 36px;
      padding: 5px 18px;
      letter-spacing: 1px;
      background-color: $color-white;
    }

    &--add-special {
      display: flex;
      align-items: center;
      max-width: 162px;
      height: 36px;
      margin-left: 22px;
      padding: 0 19px;
      line-height: 16px;
      text-align: left;
      letter-spacing: 1px;
      background-color: $color-white;
    }

    &--request-messages {
      padding: 0;

      svg {
        margin: 0;
      }
    }

    &--done,
    &--book-another {
      display: block;
      width: 100%;
      height: 40px;
      margin: 55px auto 36px;
      color: $color-white;
      font-weight: 600;
      background-color: $color-bondi-blue;
    }

    &--done {
      max-width: 110px;
    }

    &--book-another {
      max-width: 160px;
      margin: 0 auto;
    }

    &--cancel {
      display: flex;
      position: absolute;
      right: -147px;
      align-items: center;
      color: $color-white;
    }

    &--submit {
      height: 40px;
      color: $color-white;
      font-weight: 600;
      background-color: $color-bondi-blue;
    }

    &--edit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11px;
      height: 11px;
      padding: 0;
    }

    &--delete-profile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      padding: 0;
      border-radius: 50%;
      background-color: $color-persimmon;
    }

    &--delete-booking {
      height: 36px;

      & > svg {
        margin-left: 10px;
      }
    }

    &--zenkit {
      padding: 0;
      background-color: transparent;
      margin-left: 70px;

      svg {
        width: 30px;
        height: 30px;
        color: $color-neon-carrot;
      }
    }
  }

  &__radio {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 50%;
    background: $color-gray;

    &--selected::before {
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      margin: auto;
      border-radius: 50%;
      content: '';
      background-color: $color-screaming-green;
      inset: 0;
    }
  }

  &__agent-icon {
    width: 10px;
    height: 10px;
    margin-left: 11px;
  }

  &__booking-details {
    &-wrapper {
      padding: 10px 0 0 0;
      border-radius: 6px;

      &--messages {
        background-color: transparent;
      }
    }

    &-type {
      margin-left: 33px;
      text-transform: capitalize;
    }

    &-title {
      margin-right: 8px;
      color: $color-bali-hai;
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 0;

      &--diva-name {
        color: $color-azure-radiance;
      }

      &--has-agent {
        color: $color-seance;
      }
    }

    &-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-block {
        display: flex;
      }

      span {
        font-size: 12px;
        line-height: 27px;
        letter-spacing: 0;
      }

      &--request-msg {
        line-height: 16px;
        gap: 4px;
      }

      &--rate,
      &--extras {
        width: 180px;
        margin-right: 23px;
      }

      &--discount {
        span {
          color: $color-japanese-laurel;
          font-weight: bold;
        }
      }

      &--taxi-note {
        & span {
          width: 565px;
          text-overflow: ellipsis;
          letter-spacing: 0;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  &__booking-request-msg {
    margin: 6px 0;
    line-height: 16px !important;
  }

  &__info-wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 24px;
    padding: 9px 30px 10px 25px;
    overflow: hidden;
    text-align: left;
    flex-grow: 1;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      margin: auto;
      content: '';
      background-color: $color-gray;
    }

    &--for-solo {
      padding: 68px 30px 10px 25px;
    }
  }

  &__field-error {
    position: absolute;
    bottom: -2px;
    left: 11px;
    transform: translateY(100%);
    color: $color-persimmon;
    font-size: 12px;
    letter-spacing: 1px;

    &--type {
      transform: translateY(50%);
    }
  }

  &__front-wrap {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: $color-white;
    inset: 0;

    &-title {
      color: $color-gray;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
    }

    &-success {
      display: flex;
      flex-direction: column;
      align-self: start;
      margin-top: 120px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &__avatar-container {
    position: relative;
  }

  &__channel-type {
    position: absolute;
    bottom: -10px;
    left: calc(var(--info-block-padding) * -1);
    transform: translateX(-50%);
    height: 18px;
    width: 18px;
    
    &--telegram, &--robot {
      color: $color-azure-radiance;
    }
    &--whatsapp {
      color: $color-jade;
    }
    &--apple {
      color: $color-gray;
    }
  }

  &__open-map-btn {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $color-gray;

    & > svg {
      height: 22px;
      width: 22px;
      color: $color-bondi-blue;
      margin-right: 6px;
    }
  }
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  &__icon-check {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    color: $color-spring-green;
  }

  &__item {
    margin-right: 3px;
    margin-bottom: 5px;
  }

  &__item-input {
    display: none;
  }

  &__item-label {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border-radius: 6px;
    color: $color-white;
    font-weight: 500;
    font-size: 12px;
    background-color: $color-gray;
    cursor: pointer;

    &--is-paid-service {
      background-color: $color-dodger-blue;

      span {
        margin-left: 4px;
      }
    }

    &--unchecked::after {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      border: 1px solid $color-white;
      border-radius: 2px;
      content: '';
    }
  }
}
