@import "assets/scss/variables.scss";

.missed-call-item__wrap {
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:last-child){
    margin-bottom: 10px;
  }

  &--blink {
    animation: blink 0.3s linear;
  }

  &:hover {
    .missed-call-item__btn--main {
      opacity: 1;
      // margin-top: 10px;
    }
  }
}

.missed-call-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 35px);

  &__body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 40px;
    flex-grow: 1;
    transition: all 1s;
    position: relative;
    background-color: $color-athens-gray;
    border-radius: 5px;
    padding: 5px 8px 5px 5px;
    // margin: 2px 0;
  }

  &__count {
    min-width: 10px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    // margin-right: 5px;
  }

  &__voicemail-flag {
    display: flex;
    width: 100%;
    font-size: 10px;
    outline: none;
    justify-content: center;

    &-flag {
      padding: 2px 0 0 0;
    }
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    min-width: 32px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    min-width: 0;
    text-align: left;
    width: 100px;
  }

  &__duration {
    font-style: italic;
    text-align: right;
    color: $color-tundora;

    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0;
    width: 60px;
    font-size: 14px;
  }

  &__number {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    width: 105px;
    color: $color-text-primary-light;
  }

  &__operator {
    color: #0092f2;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    right: 4px;
    top: 4px;
    justify-content: center;
  }

  .call-item__btn {
    margin-left: auto;
    white-space: nowrap;
    margin: 0;
    padding: 6px 0;
  }

  &__btn {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;
    margin: 0 3px;

    &--main {
      opacity: 0;
      height: 27px;
      // margin-top: 10px;
      // transition: 0.1s all ease-out;

      &:disabled svg{
        fill: #a5a5a5;
      }
    }

    &--burger {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-left: 10px;
    }

    &--toggler {
      width: 22px;
      height: 16px;
      cursor: pointer;
      border-radius: 4px;
    }
  }

  .chat__show-more-contacts-icon {
    margin-left: 0;
    transform: translate(5px) rotate(270deg);
  }

  .missed-sub-list {
    z-index: 33;
    background-color: transparent;
    width: 100%;
    padding: 0;
    list-style: none;
    position: relative;
    border-radius: 0 0 4px 4px;
    height: auto;
    background-color: $color-dawn-pink;
    margin-top: -5px;
    padding-top: 5px;
  }
}

.missed-call-item__dropdown {
  .missed-sub-item {
    &__missed-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 5px;
      border-radius: 6px;
      font-size: 12px;
      color: $color-text-primary-light;
      position: relative;
      cursor: pointer;

      &:first-child {
        margin-top: 5px;
      }
      &::after {
        content: "";
        height: 1px;
        width: calc(100% - 10px);
        background-color: $color-azure-radiance;
        display: block;
        position: absolute;
        left: 5px;
        top: 0;
      }
    }

    &__btn {
      &--voice {
        position: absolute;
        right: 0;
        top: 5px;
      }
    }
  }
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  }
  0% {
    transform: scale(1);
  }
}
