@import '../../../../assets/scss/variables.scss';

.adr-book-item {
  display: flex;
  position: relative;
  align-items: center;
  width: calc(100% - 7px) !important;
  height: 38px !important;
  margin-bottom: 12px;
  padding: 3px 4px;
  border-radius: 6px;
  cursor: pointer;
  background-color: $color-white;

  &:hover &__external-link {
    display: flex;
  }
  
  &--disabled {
    cursor: not-allowed;
  }

  &__clear-btn {
    margin-left: 10px;
    color: $color-persimmon;
  }

  &__select-list-item {
    display: flex;
    align-items: center;
    width: calc(100% - 7px);
    height: 38px;
    margin-bottom: 12px;
    padding: 3px 4px;
    border-radius: 6px;

    & .lazy-load__container {
      width: 32px;
      height: 32px;
      overflow: hidden;
      background: transparent;
    }

    & .adr-book-item__name {
      color: $color-dodger-blue;
      font-weight: 400;
    }

    & .adr-book-item__info {
      width: 252px;
    }
  }

  &--divider-for-temporary {
    justify-content: center;
    padding: 10px 4px 3px;
    color: $color-gray;
    font-size: 14px;
    line-height: 24px;
    background: none;
    cursor: default;

    &::before, &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background: $color-gray;
    }
  }

  &__divider-temp-text {
    padding: 0 10px;
  }

  &--active {
    background-color: rgba(136, 214, 217, 0.3);
  }

  &__name {
    width: 100%;
    max-width: 295px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__contact-status {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-froly;
    &--active {
      background-color: $color-apple;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 3px;
    color: $color-gray;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
  }

  &__link-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 14px;
  }

  &__img-wrap {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-right: 5px;

    //&--off-today svg {
    //  color: $color-silver;
    //}
    //
    //&--available svg {
    //  color: $color-apple;
    //}
  }

  &__ex {
    color: #f27e3f;
    font-weight: bold;
    background-color: transparent;
  }

  &__content {
    display: flex;
    gap: 10px;
    max-height: 14.5px;
    overflow: hidden;
    width: fit-content;
    
    &-popup {
      z-index: 999;
      background-color: $color-white;
      border-radius: 6px;
      padding: 5px;
      color: $color-gray;
      font-weight: 500;
      font-size: 12px;

      display: flex;
      flex-direction: column;
      max-width: 369px;
      // max-height: 350px;
      box-shadow: $box-shadow-popups;

      & .adr-book-item__highlight-field-title {
        font-weight: bold;
        color: $color-black;
      }
    }
  }

  &__highlight {
    color: $color-red;
    background: transparent;
  }

  &__highlight-field {
    white-space: pre;
    text-overflow: ellipsis;
  }

  &__external-link {
    display: none;
    color: $color-gray;
  }

  .lazy-load__container {
    width: 32px;
    height: 32px;
    overflow: hidden;
    background: transparent;
  }

  &__ava-icon {
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 16px;
    height: 16px;
    color: $color-azure-radiance;
    border-radius: 50%;
    border: 1px solid $color-mystic;
    background-color: $color-white;

    &--telegram {
      right: 2px;
    }

    &--mail {
      color: $color-bondi-blue;
      width: 14px;
      height: 14px;
    }

    &--whatsapp {
      color: $color-bondi-blue;
    }

    &--t-group {
      right: -3px;
      background-color: $color-azure-radiance;
      color: $color-white;
    }
  }
}
